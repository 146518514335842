body {
    background: #fafafa;
    font-family: "Open Sans", sans-serif;
    /*color: #222;*/
    color: #1e2834;
}

/* ==========================================================================
re-define navbar styles to match OGR ones
========================================================================== */

.navbar {
    background: #1e2834;
    height: 70px;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-default {
    padding-top: 10px;
}

.navbar-default .navbar-nav .open .dropdown-menu {
    font-size: 12px;
}

.navbar-default .navbar-nav {
    position: relative;
    z-index: 999;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: white;
    background-color: transparent;
    box-shadow: inset 0 6px 0 0 #58a618;
    transition: box-shadow 200ms ease, color 100ms ease;
}

.navbar-default .navbar-nav > li > a:hover {
    color: white;
    background-color: transparent;
    box-shadow: inset 0 6px 0 0 #5c6267;
    transition: box-shadow 200ms ease, color 100ms ease;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu {
    color: #fff;
    background: #1e2834;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus,
.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background: #58a618;
}

.navbar-default .navbar-nav .open .dropdown-menu > .active > a {
    color: #fff;
    background: #5c6267;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    background: #1e2834;
}

.navbar-default .navbar-toggle .icon-bar {
    background: #fff;
}

.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: #1e2834;
    border-width: 0;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus,
    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:hover,
    .navbar-default .navbar-nav > .open > a:focus {
        color: white;
        background: #58a618;
    }

    .navbar-default .navbar-nav > li > a:hover {
        color: white;
        background: #5c6267;
    }
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Version number in footer
========================================================================== */
.footer-version {
    font-size: 12px;
    color: #b7b7b7; /* #ccc; */
    text-align: center;
}

.ogresearch-footer {
    font-size: 12px;
    /*color: #b7b7b7; !* #ccc; *!*/
    text-align: center;
}

.has-country-list-panel {
    width: 80%;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo and headers styles
========================================================================== */
.header-logo {
    padding-bottom: 10px;
    height: 70px;
    vertical-align: middle;
}

.button-logo {
    padding-bottom: 5px;
    height: 35px;
    vertical-align: middle;
    text-align: center;
}

.yc-chart-ico {
    padding-right: 3px;
    height: 16px;
    vertical-align: top;
}

.map-img {
    height: 400px;
}

.navbar-brand.logo {
    position: absolute;
    display: block;
}

.logo .logo-img {
    height: 35px;
    display: inline-block;
    padding-right: 15px;
    vertical-align: middle;
    padding-bottom: 10px;
}

.flag-img {
    height: 120px;
    vertical-align: bottom;
}

.page-header {
    margin: 20px 0 20px;
}

.forecast-header {
    float: left;
    font-size: 14px;
    text-align: left;
}

@media screen and (max-width: 992px) {
    .forecast-header {
        font-size: 12px;
    }

    .map-img {
        height: 300px;
    }
}

.country-page-header {
    padding-top: 40px;
    color: #46b44f;
    font-size: 54px;
    text-align: left;
    vertical-align: bottom;
}

.country-overview-panel {
    margin: 10px;
    padding: 15px;
    height: 250px;
    /*border: 1px solid #eee;*/
}

.country-note {
    margin-bottom: 10px;
    margin-top: 10px;
}

.actual-panel {
    margin-top: 10px;
    /*margin-bottom: 10px;*/
}

.archive-panel {
    /*margin-right: 5px;*/
}

.fpas-panel {

}

h1, h2, h3 {
    color: #46b44f;
    /*color: #1e2834;*/
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

.lastForeDate {
    color: #46b44f;
    font-size: 10px;
}

.greensub {
    color: #46b44f;
    /*font-weight: bold;*/
}

.motto-dashboard {
    text-align: left;
    padding-top: 20px;
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
    .motto-dashboard {
        text-align: center;
        padding-top: 0;
    }

    .country-overview-panel {
        margin: 5px;
        padding: 10px;
        height: 190px;
    }
}

@media screen and (max-width: 768px) {
    .country-page-header {
        text-align: left;
    }

    .country-overview-panel {
        margin: 5px;
        padding: 7px;
        height: 190px;
    }
}

@media screen and (max-width: 568px) {
    .forecast-header {
        font-size: 12px;
    }

    .map-img {
        height: 200px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    .flag-img {
        height: 70px;
    }

    .country-page-header {
        font-size: 36px;
        text-align: left;
    }

    .country-overview-panel {
        margin: 5px;
        padding: 5px;
        height: 190px;
    }
}

.learn-more-link {
    color: #7b8c9f;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: uppercase;
}

.learn-more-link:hover {
    color: #7b8c9f;
    text-decoration: none;
}

.more-info-icon {
    vertical-align: super;
    font-size: 80%;
}

.more-info-link {
    color: #7b8c9f;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-transform: lowercase;
}

.more-info-link:hover {
    color: #7b8c9f;
    text-decoration: none;
}

.resource-link {
    color: #1e2834;
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
}

.resource-link:hover {
    color: #1e2834;
    text-decoration: none;
}

.hr-thick {
    border: none;
    height: 2px;
    color: #ddd; /* old IE */
    background-color: #ddd; /* Modern Browsers */
}


/* ==========================================================================
yc-table directive styles
========================================================================== */

/* directive's DIV*/
.yc-table-wrapper {
    position: relative;

}

/* wrapping DIV */
.yc-table-values-wrapper {
    font-size: 12px;
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 280px;
}

.yc-table-values-wrapper.no-units-col {
    margin-left: 180px;
}

/* table element */
.yc-table {
    table-layout: fixed;
    /*margin: 0 auto;*/ /*centering on a page*/
}

/* upper panel with buttons */
.buttons-panel {
    text-align: left;
    padding-bottom: 10px;
}

.export-csv-btn,
.recalculate-btn,
.toggle-tenors-btn,
.toggle-desc-btn,
.switch-view-btn,
.reset-tbl-btn {
    color: #1e2834;
}

.recalculate-btn.is-pending-recalculate {
    font-weight: bold;
    color: #356581;
}

.export-csv-btn:hover,
.export-csv-btn:focus,
.recalculate-btn:hover,
.recalculate-btn:focus,
.toggle-tenors-btn:hover,
.toggle-tenors-btn:focus,
.toggle-desc-btn:hover,
.toggle-desc-btn:focus,
.switch-view-btn:hover,
.switch-view-btn:focus,
.reset-tbl-btn:hover,
.reset-tbl-btn:focus {
    background-color: #dbdbe6;
}

/* headers */
.yc-table-th {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    color: #46b44f;
    padding-bottom: 5px;
}

.yc-table-th.is-pending-recalculate {
    font-weight: bold;
    color: #356581;
}

.yc-table-th.is-failed {
    font-weight: bold;
    color: red;
}

.yc-table-th.is-modified {
    font-weight: bold;
}

.yc-table-th:focus,
.yc-table-th:active {
    outline-color: transparent;
}

/* table rows*/
.yc-table-tr {
    height: 38px;
}

.yc-table-tr.no-units-row {
    height: 30px;
}

.yc-table-tr.with-desc.no-units-row {
    height: 55px;
}

.yc-table-tr.with-desc {
    height: 65px;
}

/* "units" column cells */
.yc-units-th,
.yc-units-td {
    width: 100px;
    text-align: left;
    position: absolute;
    left: 180px;
}

/* "description" column cells */
.yc-desc-th,
.yc-desc-td {
    width: 180px;
    text-align: left;
    position: absolute;
    left: 0;
}

.yc-units-td,
.yc-desc-td {
    margin-top: 5px;
    line-height: 12px;
}

.yc-value-td {
    vertical-align: top;
}

.yc-value-td.calculating-now {
    background-color: #dbdbe6;
}

/* input cells */
.yc-input {
    width: 60px;
    height: 23px;
    margin: 3px;
    text-align: center;
    border-color: #a3cd8f;
    border-width: 2px;
    border-radius: 2px;
    color: inherit;
    -webkit-text-fill-color: inherit;
    font-size: inherit;
    box-shadow: none;
}

.yc-input.is-modified {
    font-weight: bold;
}

.yc-input.is-pending-recalculate {
    font-weight: bold;
    color: #356581;
}

.yc-input.is-failed {
    font-weight: bold;
    color: red;
}

.yc-units-div {
    font-size: 10px;
    margin-top: 3px;
    margin-left: 5px;
    line-height: 10px;
    font-weight: normal;
    font-style: italic;
}

.desc-detailed {
    font-size: 10px;
    margin-top: 5px;
    margin-left: 5px;
    width: 650px;
    line-height: 10px;
    font-weight: normal;
    font-style: italic;
}

.desc-detailed.no-units-row {
    margin-top: 10px;
}

input[disabled].yc-input {
    border-color: #dbdbe6; /*#ccc;*/
    background-color: white;
    border-width: 1px;
    cursor: default;
}

.yc-end-blk-row {
    margin-bottom: 30px;
}

.th-info-icon {
    vertical-align: super;
    font-size: 80%;
    cursor: pointer;
}

.tooltip.tenor-info-tooltip .tooltip-inner {
    color: #1e2834;
    background-color: #dbdbe6;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    font-size: 10px;
}

.tooltip.tenor-info-tooltip .tooltip-arrow {
    display: none;
}

@media screen and (max-width: 768px) {
    .desc-detailed {
        font-size: 10px;
        width: 450px;
    }
}

@media screen and (max-width: 568px) {
    .yc-table-values-wrapper {
        font-size: 10px;
        margin-left: 205px;
    }

    .yc-table-values-wrapper.no-units-col {
        margin-left: 120px;
    }

    .yc-table-th {
        font-size: 12px;
    }

    .yc-desc-th,
    .yc-desc-td {
        width: 120px;
    }

    .yc-units-th,
    .yc-units-td {
        width: 85px;
        left: 120px;
    }

    .yc-table-tr.with-desc {
        height: 60px;
    }

    .yc-units-div {
        margin-top: 3px;
        margin-left: 3px;
    }

    .desc-detailed {
        margin-left: 3px;
        width: 400px;
    }
}

/* ==========================================================================
chart pane responsiveness
========================================================================== */

.chart-pane {
    height: 550px;
    /*max-width: 1200px;*/
}

@media screen and (max-width: 1200px) {
    .chart-pane {
        height: 400px;
    }
}

@media screen and (max-width: 992px) {
    .chart-pane {
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .chart-pane {
        height: 300px;
    }
}

.yc-chart-control-box-header {
    display: block;
    font-weight: normal;
    font-size: 14px;
    color: #46b44f;
    padding: 5px;
}

.multiple-date-picker {
    font-size: 12px;
}


.picker-day:not(.picker-off):not(.picker-empty):not(.picker-selected),
.picker-day.today:not(.picker-off):not(.picker-empty):not(.picker-selected) {
    font-weight: bold;
    color: #1e2834;
}

.picker-day.picker-selected,
.picker-day.today.picker-selected,
.picker-day:not(.picker-off):not(.picker-empty):hover,
.picker-day.today:not(.picker-off):not(.picker-empty):hover {
    background-color: #46b44f;
    color: #fff;
    cursor: pointer;
}

.picker-day.today.picker-off,
.picker-day.today.picker-off:hover {
    color: #bbb;
    font-weight: normal;
}


/*.picker-day.picker-off{*/
/*    color: #dbdbe6;*/
/*    background-color: #fafafa;*/
/*}*/

.btn-group-sm > .curve-type-btn {
    font-size: 10px;
}

.tenor-pick-btn {
    margin: 5px;
    font-size: 10px;
}

.sharpe-ratio-pick-btn {
    margin: 5px;
    font-size: 12px;
}

.curve-type-btn:hover,
.curve-type-btn:focus,
.dates-clear-btn:hover,
.dates-clear-btn:focus,
.tenor-pick-btn:hover,
.tenor-pick-btn:focus,
.sharpe-ratio-pick-btn:hover,
.sharpe-ratio-pick-btn:focus {
    background-color: #dbdbe6;
}

.curve-type-btn.active,
.curve-type-btn.active:focus,
.curve-type-btn.active:hover,
.tenor-pick-btn.active,
.tenor-pick-btn.active:focus,
.tenor-pick-btn.active:hover,
.sharpe-ratio-pick-btn.active,
.sharpe-ratio-pick-btn.active:focus,
.sharpe-ratio-pick-btn.active:hover {
    color: #fff;
    background-color: #1e2834;
}

/* ==========================================================================
styles of the country navigation scroll pane
========================================================================== */
.flag-img-tab {
    height: 18px;
    vertical-align: middle;
}

.country-tab-nav > li > a {
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    /*font-size: 24px;*/
    /*color: #777777;*/

    /*background-color: #fafafa;*/
    color: #767f88;
}

.country-tab-nav > li > a:hover {
    background-color: #dbdbe6;
}

/*.country-tab-nav > li > a:hover,*/
/*.country-tab-nav > li > a:focus {*/
/*    color: #46b44f;*/
/*}*/

.country-tab-nav > li.active > a,
.country-tab-nav > li.active > a:hover,
.country-tab-nav > li.active > a:focus {
    /*color: white;*/
    /*background-color: #46b44f;*/
    color: #1e2834;
    background-color: #fafafa;
    font-weight: bold;
}

@media screen and (max-width: 568px) {
    .country-tab-nav > li > a {
        /*    padding-top: 5px;*/
        /*    padding-bottom: 5px;*/
        font-size: 12px;
    }

    .flag-img-tab {
        height: 14px;
    }
}

.country-nav > li > a {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 11px;
    color: #fff;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: white;
    background: #58a618;
}

.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
    color: white;
    background: #5c6267;
}

.report-area {
    /*height: 100%;*/
    /*width: 80%;*/
}

.products-with-background {
    background: url("../images/map-world-green-all-opaque.png") no-repeat center top;
    background-size: contain;
}

/* ==========================================================================
styles of the chart navigation pane
========================================================================== */
.chart-tab-nav {
    margin-bottom: 10px;
}

.chart-tab-nav > li > a {
    color: #767f88;
    font-size: 12px;
}

.chart-tab-nav > li > a:hover {
    background-color: #dbdbe6;
}

.chart-tab-nav > li.active > a,
.chart-tab-nav > li.active > a:hover,
.chart-tab-nav > li.active > a:focus {
    color: #1e2834;
    /*background-color: #fafafa;*/
    font-weight: bold;
}

@media screen and (max-width: 568px) {
    .chart-tab-nav > li > a {
        font-size: 10px;
    }
}

/* ==========================================================================
Slider styles
========================================================================== */
.slider.slider-vertical {
    height: 75px;
}

.slider-selection, .slider-track-high {
    background: #dbdbe6;
}

.slider-handle {
    background-color: #1e2834;
    background-image: -webkit-linear-gradient(top, #354455 0, #1e2834 100%);
    background-image: -o-linear-gradient(top, #354455 0, #1e2834 100%);
    background-image: linear-gradient(to bottom, #354455 0, #1e2834 100%);
}

/* ==========================================================================
Main page styles
========================================================================== */
.ogr-shield {
    display: inline-block;
    width: 428px;
    height: 449px;
    background: url("../images/ogr-shield.jpg") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/ogr-shield2x.jpg") no-repeat center top;
        background-size: contain;
    }
}

.ui-tabs-scrollable > .nav-button.right-nav-button:before,
.ui-tabs-scrollable > .nav-button.left-nav-button:before,
.nav-link.ng-binding {
    color: #777;
}

/* ==========================================================================
Generic styles
========================================================================== */
.container {
    /*width: 95%;*/
    min-width: 320px;
}

.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: 5px;
}

.voffset2 {
    margin-top: 10px;
}

.voffset3 {
    margin-top: 15px;
}

.voffset4 {
    margin-top: 30px;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
Font Awesome icons extra style and animation
========================================================================== */

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: 0.5s;
}

.fa-rotate-90 {
    transition: 0.5s;
}

/* ==========================================================================
calculating splash
========================================================================== */
.calculating-splash {
    height: 50%;
    width: 50%;
    position: absolute;
    z-index: 1; /* Sit on top */
    left: 25%;
    margin-left: 0;
    top: 25%;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.05); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    transition: 1.5s;
    text-align: center;
    line-height: 100%;
}

/* The ghost, nudged to maintain perfect centering */
.calculating-splash:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
}

.splash-content {
    display: inline-block;
    vertical-align: middle;
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden; /* Disable horizontal scroll */
    padding: 5%;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
make sure browsers do not show up-down arrows for numerical inputs
========================================================================== */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    vertical-align: 2px;
}

.point:last {
    margin: 0 !important;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
    text-overflow: ellipsis;
}

.alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

.alert-success {
    color: #46b44f;
    background-color: #fafffa;
    border-color: #d0e9d0;
}

.alert-success .alert-link {
    color: #3b8b44;
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
    width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
}

.modal {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.modal-header {
    background: #f0f0f0;
}

.modal-header.bg-warning {
    background: #f0ad4e;
}


.container-accordion-heading {
    padding-top: 4px;
    white-space: nowrap;
    line-height: normal;
    color: #1e2834;
}

.container-accordion-heading:hover,
.container-accordion-heading:focus,
.accordion-toggle:hover,
.accordion-toggle:focus,
.cpi-archive-header:hover,
.cpi-archive-header:focus {
    outline: none;
    box-shadow: none;
}

.panel-default > .panel-heading {
    background-color: #dbdbe6;
}

.panel-title > a:hover,
.panel-title > a:focus {
    text-decoration: none;
}

.cpi-archive {
    border-style: solid;
    border-width: 1px;
    border-color: #dbdbe6;
    border-radius: 3px;
    padding: 0 10px;
}

/* jhipster-needle-css-add-main JHipster will add new css style */
;